/**
 * Import SASS 
 * Combining files into one, adds them in sequence
 */
/**
 * Global Variables
 */
/* Breakpoint Screen Sizes */
/* Breakpoint Font Root Sizes */
/* Colors */
/* Official Social Media Colors */
/* Fonts */
/**
 * Mixins
 */
/**
 * Media Queries
 */
/**
 * hoverTouch.js implementation
 */
/**
 * Convert pixel sizes to REM
 * @arg  {string}  $pixels  The pixel size to convert to REM
 * @arg  {string}  $root    The root size to convert by
 * @return  {string}  the calculated REM size
 */
/**
 * Convert pixel sizes to VW
 * @arg  {string}  $pixels  The pixel size to convert to REM
 * @arg  {string}  $width   The width to convert by
 * @return  {string}  the calculated VW size
 */
/**
 * Custom Scrollbar Styling
 * @param  {integer} $size       Enter in the increments for size of the scrollbar
 *                               EX: ".5em"
 * @param  {string}  $primary    Enter in the color desired for the primary color
 * @param  {string}  $secondary  Enter in the color desired for the secondary color
 */
/**
 * Imports
 * Add external files into combined sass file
 */
@import url("//use.fontawesome.com/releases/v5.7.2/css/all.css");
@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700");
@import url("//fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i");
@font-face {
  font-family: 'Hitchcock';
  src: url("../fonts/Hitchcock.woff2") format("woff2"), url("../fonts/Hitchcock.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/**
 * Keyframe Animations
 */
@-webkit-keyframes show {
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; }
  1% {
    z-index: 3; }
  100% {
    opacity: 1;
    visibility: visible;
    z-index: 3; } }
@keyframes show {
  0% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; }
  1% {
    z-index: 3; }
  100% {
    opacity: 1;
    visibility: visible;
    z-index: 3; } }

@-webkit-keyframes hide {
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 3; }
  99% {
    z-index: 3; }
  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; } }

@keyframes hide {
  0% {
    opacity: 1;
    visibility: visible;
    z-index: 3; }
  99% {
    z-index: 3; }
  100% {
    opacity: 0;
    visibility: hidden;
    z-index: -2; } }

@-webkit-keyframes rotateClockwise {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotateClockwise {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/**
 * Global
 */
html, body {
  font-size: 16px; }
  @media only screen and (min-width: 320px) {
    html, body {
      font-size: 2.13333vw; } }
  @media only screen and (min-width: 751px) {
    html, body {
      font-size: 0.94488vw; } }
  @media only screen and (min-width: 1271px) {
    html, body {
      font-size: 0.625vw; } }

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  color: #000;
  background-color: #fff; }

html {
  margin-top: 0 !important; }

#site {
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 2.25rem;
  line-height: 3.75rem; }
  @media only screen and (min-width: 751px) {
    #site {
      font-size: 2rem;
      line-height: 3rem; } }
  @media only screen and (min-width: 1271px) {
    #site {
      font-size: 1.8rem;
      line-height: 3rem; } }

#site-holder {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media only screen and (min-width: 751px) {
    #site-holder {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }
  @media only screen and (min-width: 1271px) {
    #site-holder {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }

#site-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 46.875rem; }
  @media only screen and (min-width: 751px) {
    #site-content {
      width: 79.16667rem; } }
  @media only screen and (min-width: 1271px) {
    #site-content {
      width: 125rem; } }

img {
  display: block;
  width: 100%;
  height: auto; }

h1, h2, h3 {
  font-weight: normal; }

p {
  margin: 1.875rem 0; }
  @media only screen and (min-width: 751px) {
    p {
      margin: 1.66667rem 0; } }
  @media only screen and (min-width: 1271px) {
    p {
      margin: 3rem 0; } }

a:link {
  color: #3375e2;
  text-decoration: none; }

a:visited {
  color: #3375e2;
  text-decoration: none; }

a:hover {
  color: #000;
  text-decoration: none; }

a:active {
  color: #3375e2;
  text-decoration: none; }

.screen-reader-text {
  /* Reusable, toolbox kind of class */
  position: absolute;
  top: -9999px;
  left: -9999px; }

@media only screen and (min-width: 751px) {
  .mobile {
    display: none; } }

.desktop {
  display: none; }
  @media only screen and (min-width: 751px) {
    .desktop {
      display: block; } }

figure {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0;
  -moz-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 40px;
  -moz-margin-end: 40px;
  margin-inline-end: 40px; }

nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #3375e2;
  width: 46.875rem; }
  @media only screen and (min-width: 751px) {
    nav {
      width: 25rem; } }
  @media only screen and (min-width: 1271px) {
    nav {
      width: 35rem; } }

#nav-logo {
  z-index: 1003;
  width: 31.25rem;
  height: 29.6875rem;
  margin: 0 7.8125rem; }
  @media only screen and (min-width: 751px) {
    #nav-logo {
      width: 20.83333rem;
      height: 19.16667rem;
      margin: 0 2.08333rem; } }
  @media only screen and (min-width: 1271px) {
    #nav-logo {
      width: 30rem;
      height: 28rem;
      margin: 0 2.5rem; } }

#nav-logo img {
  width: 100%;
  height: auto; }

#menu-btn, #schedule-btn {
  cursor: pointer;
  text-align: center;
  font-family: 'Hitchcock', san-serif;
  color: #FFF;
  background-color: #6b6b6b;
  font-size: 4.0625rem;
  padding: 1.25rem 0; }
  @media only screen and (min-width: 751px) {
    #menu-btn, #schedule-btn {
      font-size: 2.25rem;
      padding: 0.83333rem 0; } }
  @media only screen and (min-width: 1271px) {
    #menu-btn, #schedule-btn {
      font-size: 3.6rem;
      padding: 1rem 0; } }

#menu-btn:hover, #schedule-btn:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none; }

#nav-btns,
#schedule-menu {
  overflow: hidden;
  height: 0; }
  @media only screen and (min-width: 751px) {
    #nav-btns,
    #schedule-menu {
      height: auto; } }
  #nav-btns.active,
  #schedule-menu.active {
    height: auto; }
    @media only screen and (min-width: 751px) {
      #nav-btns.active,
      #schedule-menu.active {
        height: 0; } }
    @media only screen and (min-width: 1271px) {
      #nav-btns.active,
      #schedule-menu.active {
        height: 0; } }

#social-nav {
  text-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.nav-btn {
  text-align: center;
  font-family: "Hitchcock", san-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
  font-size: 3.75rem;
  padding: 1.25rem 0;
  width: 100%; }
  @media only screen and (min-width: 751px) {
    .nav-btn {
      font-size: 2rem;
      padding: 0.41667rem 0; } }
  @media only screen and (min-width: 1271px) {
    .nav-btn {
      font-size: 2.4rem;
      padding: 0.5rem 0; } }

.nav-btn:hover {
  color: #000;
  background-color: #fff;
  text-decoration: none; }

.blue-btn {
  font-family: "Hitchcock", san-serif;
  background-color: #3375e2;
  vertical-align: center;
  color: #fff;
  margin: 1.25rem;
  font-size: 3.75rem;
  padding: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .blue-btn {
      margin: 1.25rem;
      font-size: 2.33333rem;
      padding: 1.25rem; } }
  @media only screen and (min-width: 1271px) {
    .blue-btn {
      margin: 0.625rem;
      font-size: 2.8rem;
      padding: 1rem 1.5rem; } }

.social-btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: #fbf1e3;
  font-size: 3.125rem;
  padding: 1.5625rem 1.5625rem; }
  @media only screen and (min-width: 751px) {
    .social-btn {
      font-size: 2.25rem;
      padding: 1.66667rem 0.83333rem; } }
  @media only screen and (min-width: 1271px) {
    .social-btn {
      font-size: 2.7rem;
      padding: 2rem 1rem; } }

.social-btn:hover {
  color: #000;
  text-decoration: none; }

.section-header {
  background-color: #bbbbbb;
  height: 5rem; }
  .section-header.gray {
    background-color: #6b6b6b; }
  .section-header.blue {
    background-color: #3375e2; }
  @media only screen and (min-width: 751px) {
    .section-header {
      height: 4rem; } }
  @media only screen and (min-width: 1271px) {
    .section-header {
      height: 4.8rem; } }
  .section-header .title {
    position: relative;
    display: inline-block;
    font-family: "Hitchcock", san-serif;
    color: #FFF;
    background-color: #000;
    -webkit-transform: rotate(-3deg) translateY(0.3125rem);
    -ms-transform: rotate(-3deg) translateY(0.3125rem);
    transform: rotate(-3deg) translateY(0.3125rem);
    padding: 0.9375rem;
    font-size: 2.75rem;
    margin: 0 0 0 0.625rem; }
    @media only screen and (min-width: 751px) {
      .section-header .title {
        -webkit-transform: rotate(-3deg) translateY(0.41667rem);
        -ms-transform: rotate(-3deg) translateY(0.41667rem);
        transform: rotate(-3deg) translateY(0.41667rem);
        padding: 0.41667rem;
        font-size: 3rem;
        margin: 0 0 0 0.83333rem; } }
    @media only screen and (min-width: 1271px) {
      .section-header .title {
        -webkit-transform: rotate(-3deg) translateY(0.5rem);
        -ms-transform: rotate(-3deg) translateY(0.5rem);
        transform: rotate(-3deg) translateY(0.5rem);
        padding: 0.5rem;
        font-size: 3.6rem;
        margin: 0 0 0 1rem; } }
  .section-header .more-btn {
    display: inline-block;
    vertical-align: top;
    font-family: "Hitchcock", san-serif;
    color: #FFF;
    font-size: 2.25rem;
    margin: 1.5625rem; }
    @media only screen and (min-width: 751px) {
      .section-header .more-btn {
        font-size: 2rem;
        margin: 1.25rem; } }
    @media only screen and (min-width: 1271px) {
      .section-header .more-btn {
        font-size: 2.4rem;
        margin: 1.5rem; } }
  .section-header .more-btn:hover {
    color: #000; }

.news-thumbs, .photos-thumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.news-thumbs,
.schedule-thumbs {
  margin: 1.875rem; }
  @media only screen and (min-width: 751px) {
    .news-thumbs,
    .schedule-thumbs {
      margin: 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .news-thumbs,
    .schedule-thumbs {
      margin: 5rem; } }

.news-thumbs a .info,
.news-thumbs a:link .info,
.news-thumbs a:active .info,
.news-thumbs a:visited .info,
.thumb-holder a .info,
.thumb-holder a:link .info,
.thumb-holder a:active .info,
.thumb-holder a:visited .info,
.schedule-thumbs a .info,
.schedule-thumbs a:link .info,
.schedule-thumbs a:active .info,
.schedule-thumbs a:visited .info {
  color: #000; }
  .news-thumbs a .info .title,
  .news-thumbs a:link .info .title,
  .news-thumbs a:active .info .title,
  .news-thumbs a:visited .info .title,
  .thumb-holder a .info .title,
  .thumb-holder a:link .info .title,
  .thumb-holder a:active .info .title,
  .thumb-holder a:visited .info .title,
  .schedule-thumbs a .info .title,
  .schedule-thumbs a:link .info .title,
  .schedule-thumbs a:active .info .title,
  .schedule-thumbs a:visited .info .title {
    color: #3375e2; }

.news-thumbs a .thumb-featurette img,
.news-thumbs a .poster-thumb img,
.news-thumbs a .thumb-img img,
.news-thumbs a:link .thumb-featurette img,
.news-thumbs a:link .poster-thumb img,
.news-thumbs a:link .thumb-img img,
.news-thumbs a:active .thumb-featurette img,
.news-thumbs a:active .poster-thumb img,
.news-thumbs a:active .thumb-img img,
.news-thumbs a:visited .thumb-featurette img,
.news-thumbs a:visited .poster-thumb img,
.news-thumbs a:visited .thumb-img img,
.thumb-holder a .thumb-featurette img,
.thumb-holder a .poster-thumb img,
.thumb-holder a .thumb-img img,
.thumb-holder a:link .thumb-featurette img,
.thumb-holder a:link .poster-thumb img,
.thumb-holder a:link .thumb-img img,
.thumb-holder a:active .thumb-featurette img,
.thumb-holder a:active .poster-thumb img,
.thumb-holder a:active .thumb-img img,
.thumb-holder a:visited .thumb-featurette img,
.thumb-holder a:visited .poster-thumb img,
.thumb-holder a:visited .thumb-img img,
.schedule-thumbs a .thumb-featurette img,
.schedule-thumbs a .poster-thumb img,
.schedule-thumbs a .thumb-img img,
.schedule-thumbs a:link .thumb-featurette img,
.schedule-thumbs a:link .poster-thumb img,
.schedule-thumbs a:link .thumb-img img,
.schedule-thumbs a:active .thumb-featurette img,
.schedule-thumbs a:active .poster-thumb img,
.schedule-thumbs a:active .thumb-img img,
.schedule-thumbs a:visited .thumb-featurette img,
.schedule-thumbs a:visited .poster-thumb img,
.schedule-thumbs a:visited .thumb-img img {
  outline: 0px solid #3375e2;
  outline-offset: 0px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }

.news-thumbs a:hover .thumb-featurette img,
.news-thumbs a:hover .poster-thumb img,
.news-thumbs a:hover .thumb-img img,
.thumb-holder a:hover .thumb-featurette img,
.thumb-holder a:hover .poster-thumb img,
.thumb-holder a:hover .thumb-img img,
.schedule-thumbs a:hover .thumb-featurette img,
.schedule-thumbs a:hover .poster-thumb img,
.schedule-thumbs a:hover .thumb-img img {
  outline: 10px solid #3375e2;
  outline-offset: -10px; }

.banner {
  display: inherit;
  border: 1px solid #000; }
  .banner.size250x250 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size250x250 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size250x250 {
        width: 98rem; } }
  .banner.size200x200 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size200x200 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size200x200 {
        width: 98rem; } }
  .banner.size468x60 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size468x60 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size468x60 {
        width: 98rem; } }
  .banner.size728x90 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size728x90 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size728x90 {
        width: 98rem; } }
  .banner.size300x250 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size300x250 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size300x250 {
        width: 98rem; } }
  .banner.size336x280 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size336x280 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size336x280 {
        width: 98rem; } }
  .banner.size120x600 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size120x600 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size120x600 {
        width: 98rem; } }
  .banner.size160x600 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size160x600 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size160x600 {
        width: 98rem; } }
  .banner.size300x600 {
    width: 45.5rem; }
    @media only screen and (min-width: 751px) {
      .banner.size300x600 {
        width: 60.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size300x600 {
        width: 98rem; } }
  .banner.size750x200 {
    margin: 0;
    width: 46.875rem; }
    @media only screen and (min-width: 751px) {
      .banner.size750x200 {
        width: 25rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size750x200 {
        width: 34.8rem; } }
  .banner.size980x120 {
    width: 45.625rem;
    margin: 1.25rem auto; }
    @media only screen and (min-width: 751px) {
      .banner.size980x120 {
        width: 81.66667rem;
        margin: 1.66667rem auto; } }
    @media only screen and (min-width: 1271px) {
      .banner.size980x120 {
        width: 98rem;
        margin: 2rem auto; } }
  .banner.size1250x120 {
    width: 46.625rem;
    height: 5.8125rem; }
    @media only screen and (min-width: 751px) {
      .banner.size1250x120 {
        width: 75.83333rem;
        height: 10rem; } }
    @media only screen and (min-width: 1271px) {
      .banner.size1250x120 {
        margin: 2rem auto;
        width: 121rem;
        height: 15rem; } }

.thumb-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.category-your-hosts .thumb-holder {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.category-your-hosts .excerpt {
  display: none; }

.photo-gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .photo-gallery .photo-thumb {
    cursor: pointer;
    width: 15.625rem; }
    @media only screen and (min-width: 751px) {
      .photo-gallery .photo-thumb {
        width: 16.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .photo-gallery .photo-thumb {
        width: 20rem; } }

.page-content,
.post-content {
  padding: 1.5625rem 3.125rem; }
  @media only screen and (min-width: 751px) {
    .page-content,
    .post-content {
      padding: 2.08333rem 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .page-content,
    .post-content {
      padding: 2.5rem 5rem; } }
  .page-content iframe,
  .post-content iframe {
    width: 39.375rem;
    height: 23.75rem; }
    @media only screen and (min-width: 751px) {
      .page-content iframe,
      .post-content iframe {
        width: 70.83333rem;
        height: 39.83333rem; } }
    @media only screen and (min-width: 1271px) {
      .page-content iframe,
      .post-content iframe {
        width: 115rem;
        height: 64.6rem; } }
  .page-content .laurel-img,
  .post-content .laurel-img {
    float: left;
    width: 50%; }

.page-nav {
  text-align: center;
  font-size: 6.25rem;
  margin: 1.5625rem; }
  @media only screen and (min-width: 751px) {
    .page-nav {
      text-align: left;
      font-size: 4.16667rem;
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .page-nav {
      font-size: 4.8rem;
      margin: 2rem; } }
  .page-nav a {
    margin: 0.625rem; }
    @media only screen and (min-width: 751px) {
      .page-nav a {
        margin: 0.83333rem; } }
    @media only screen and (min-width: 1271px) {
      .page-nav a {
        margin: 1rem; } }

.social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1.25rem; }
  .social .share-btn {
    color: #3375e2;
    font-size: 6.25rem;
    margin: 1.5625rem; }
    @media only screen and (min-width: 751px) {
      .social .share-btn {
        font-size: 4.16667rem;
        margin: 0.83333rem; } }
    @media only screen and (min-width: 1271px) {
      .social .share-btn {
        font-size: 5rem;
        margin: 1.5rem; } }
    .social .share-btn:hover {
      color: #000; }

.ff-btn img {
  margin: 3.125rem auto;
  width: 12.5rem; }
  @media only screen and (min-width: 751px) {
    .ff-btn img {
      margin: 4.16667rem auto;
      width: 16.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .ff-btn img {
      margin: 5rem auto;
      width: 20rem; } }

.twitch-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.5625rem 3.125rem; }
  @media only screen and (min-width: 751px) {
    .twitch-holder {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 2.08333rem 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .twitch-holder {
      padding: 2.5rem 5rem; } }

#twitch_feed {
  width: 39.0625rem;
  height: 21.875rem; }
  @media only screen and (min-width: 751px) {
    #twitch_feed {
      width: 45.83333rem;
      height: 25.83333rem; } }
  @media only screen and (min-width: 1271px) {
    #twitch_feed {
      width: 90rem;
      height: 50.6rem; } }

#chat_embed {
  width: 39.0625rem;
  height: 19.375rem; }
  @media only screen and (min-width: 751px) {
    #chat_embed {
      width: 22.91667rem;
      height: 25.83333rem; } }
  @media only screen and (min-width: 1271px) {
    #chat_embed {
      width: 20rem;
      height: 50.6rem; } }

.overlay {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: 0;
  display: none;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-size: 2.25rem; }
  @media only screen and (min-width: 751px) {
    .overlay {
      font-size: 3rem; } }
  @media only screen and (min-width: 1271px) {
    .overlay {
      font-size: 3.6rem; } }
  .overlay.active {
    display: block;
    opacity: 1; }
  .overlay .holder {
    min-width: 100vw;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .overlay .holder .content {
      opacity: 0;
      width: 58.33333rem;
      -webkit-transition: opacity 0.5s ease-in-out 0.1s;
      transition: opacity 0.5s ease-in-out 0.1s; }
      @media only screen and (min-width: 751px) {
        .overlay .holder .content {
          width: 83.33333rem; } }
      @media only screen and (min-width: 1271px) {
        .overlay .holder .content {
          width: 100rem; } }
      .overlay .holder .content.active {
        opacity: 1; }
      .overlay .holder .content.videos {
        height: 26.375rem; }
        @media only screen and (min-width: 751px) {
          .overlay .holder .content.videos {
            width: 83.33333rem;
            height: 46.83333rem; } }
        @media only screen and (min-width: 1271px) {
          .overlay .holder .content.videos {
            width: 100rem;
            height: 56.2rem; } }
      .overlay .holder .content.tall {
        width: auto;
        height: 90vh; }
        @media only screen and (min-width: 751px) {
          .overlay .holder .content.tall {
            width: auto;
            height: 90vh; } }
        @media only screen and (min-width: 1271px) {
          .overlay .holder .content.tall {
            width: auto;
            height: 100vh; } }
        .overlay .holder .content.tall img {
          width: auto;
          height: 100%; }
  .overlay .close-btn {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 3.125rem;
    right: 3.125rem; }
    @media only screen and (min-width: 751px) {
      .overlay .close-btn {
        top: 4.16667rem;
        right: 4.16667rem; } }
    @media only screen and (min-width: 1271px) {
      .overlay .close-btn {
        top: 5rem;
        right: 5rem; } }
  .overlay .close-btn:hover {
    color: #bbbbbb; }
  .overlay .social-nav {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #fff;
    position: absolute;
    top: 6.25rem;
    right: 3.125rem; }
    @media only screen and (min-width: 751px) {
      .overlay .social-nav {
        top: 8.33333rem;
        right: 4.16667rem; } }
    @media only screen and (min-width: 1271px) {
      .overlay .social-nav {
        top: 10rem;
        right: 5rem; } }

.overlay-arrow {
  color: #fff;
  cursor: initial;
  margin: 5.625rem;
  font-size: 3.125rem;
  opacity: 0.2;
  display: none;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  @media only screen and (min-width: 751px) {
    .overlay-arrow {
      display: block;
      margin: 7.5rem 3.75rem;
      font-size: 3.33333rem; } }
  @media only screen and (min-width: 1271px) {
    .overlay-arrow {
      margin: 9rem 4.5rem;
      font-size: 3.6rem; } }
  .overlay-arrow.active {
    cursor: pointer;
    opacity: 1; }

.overlay-arrow:hover.active {
  color: #bbbbbb; }

.single header {
  border: 1px solid #000;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .single header {
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .single header {
      margin: 2rem; } }
  .single header.photo-header {
    display: inline-block;
    width: 43.75rem;
    height: auto;
    margin-bottom: 0; }
    .single header.photo-header img {
      width: 100%;
      height: auto;
      margin-bottom: 0; }
    @media only screen and (min-width: 751px) {
      .single header.photo-header {
        width: auto;
        height: 70vh;
        margin-bottom: 0; }
        .single header.photo-header img {
          width: auto;
          height: 100%; } }

.single .social {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 1.25rem; }
  @media only screen and (min-width: 751px) {
    .single .social {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      margin: 0 1.25rem; } }
  @media only screen and (min-width: 1271px) {
    .single .social {
      margin: 0 1.5rem; } }

.single > .info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row nowrap;
  -ms-flex-direction: row nowrap;
  flex-direction: row nowrap;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .single > .info {
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .single > .info {
      margin: 2rem; } }

.single > .title {
  font-family: "Hitchcock", san-serif;
  color: #3375e2;
  text-align: center;
  font-size: 3.125rem;
  line-height: 3.3125rem;
  margin: 1.875rem; }
  @media only screen and (min-width: 751px) {
    .single > .title {
      text-align: left;
      font-size: 4rem;
      line-height: 4.58333rem;
      margin: 1.25rem; } }
  @media only screen and (min-width: 1271px) {
    .single > .title {
      font-size: 4.8rem;
      line-height: 5.5rem;
      margin: 2rem 2rem; } }

.single > .date {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
  margin: 0.625rem 1.875rem;
  text-align: center; }
  @media only screen and (min-width: 751px) {
    .single > .date {
      text-align: left;
      font-size: 2.33333rem;
      line-height: 2.66667rem;
      margin: 0.83333rem 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .single > .date {
      font-size: 2.1rem;
      line-height: 2.4rem;
      margin: 1rem 2rem; } }

.single > .content {
  text-align: center;
  margin: 1.25rem 1.875rem; }
  @media only screen and (min-width: 751px) {
    .single > .content {
      text-align: left;
      margin: 0.83333rem 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .single > .content {
      margin: 1rem 2rem; } }
  .single > .content .wp-block-image img {
    width: auto; }

.next-prev {
  margin: 0.625rem 1.25rem; }
  @media only screen and (min-width: 751px) {
    .next-prev {
      margin: 0.83333rem 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .next-prev {
      margin: 1rem 2rem; } }

.category-photos .thumb-holder {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.thumb-photos {
  cursor: pointer;
  width: 15.625rem; }
  @media only screen and (min-width: 751px) {
    .thumb-photos {
      width: 8.08333rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-photos {
      width: 12.5rem; } }

.videos {
  cursor: pointer; }

.thumb-videos {
  cursor: pointer;
  text-align: center;
  width: 20.9375rem;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .thumb-videos {
      width: 16.66667rem;
      margin: 0.83333rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-videos {
      width: 20rem;
      margin: 1rem; } }
  .thumb-videos img {
    margin-bottom: 1.25rem; }
    @media only screen and (min-width: 751px) {
      .thumb-videos img {
        margin-bottom: 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-videos img {
        margin-bottom: 1.5rem; } }
  .thumb-videos h4 {
    color: #000;
    margin: 0;
    text-align: center;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 2.0625rem; }
    @media only screen and (min-width: 751px) {
      .thumb-videos h4 {
        font-size: 1.5rem;
        line-height: 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-videos h4 {
        font-size: 2.1rem;
        line-height: 2.4rem; } }

.category-schedule .thumb-holder {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.schedule .info-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row nowrap;
  -ms-flex-direction: row nowrap;
  flex-direction: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3375e2;
  color: #fff;
  padding: 0.41667rem 1.25rem; }
  @media only screen and (min-width: 751px) {
    .schedule .info-banner {
      padding: 0.41667rem 1.25rem; } }
  @media only screen and (min-width: 1271px) {
    .schedule .info-banner {
      padding: 0.5rem 1.5rem; } }
  .schedule .info-banner .day-holder {
    margin-right: 1rem; }
    .schedule .info-banner .day-holder .day {
      font-family: "Hitchcock", san-serif;
      color: #FFF;
      background-color: #000;
      font-size: 2.875rem;
      padding: 1.25rem 1.25rem; }
      @media only screen and (min-width: 751px) {
        .schedule .info-banner .day-holder .day {
          font-size: 2.33333rem;
          padding: 1.66667rem 1.66667rem; } }
      @media only screen and (min-width: 1271px) {
        .schedule .info-banner .day-holder .day {
          font-size: 2.8rem;
          padding: 1rem 1rem; } }
  .schedule .info-banner .date-time {
    font-family: "Hitchcock", san-serif;
    color: #fff;
    font-size: 2.75rem; }
    @media only screen and (min-width: 751px) {
      .schedule .info-banner .date-time {
        font-size: 3rem; } }
    @media only screen and (min-width: 1271px) {
      .schedule .info-banner .date-time {
        font-size: 2.8rem;
        line-height: 3.3rem; } }

.schedule .block-selections {
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .schedule .block-selections {
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .schedule .block-selections {
      margin: 2rem; } }
  .schedule .block-selections .block-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media only screen and (min-width: 751px) {
      .schedule .block-selections .block-info {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start; } }
    .schedule .block-selections .block-info .poster-thumb {
      border: 1px solid #000;
      width: 31.25rem;
      margin: 0 0 1.25rem 0; }
      @media only screen and (min-width: 751px) {
        .schedule .block-selections .block-info .poster-thumb {
          width: 21.66667rem;
          margin: 0 1.66667rem 0 0; } }
      @media only screen and (min-width: 1271px) {
        .schedule .block-selections .block-info .poster-thumb {
          width: 24rem;
          margin: 0 2rem 0 0; } }
    .schedule .block-selections .block-info > .block-host {
      text-align: center; }
      @media only screen and (min-width: 751px) {
        .schedule .block-selections .block-info > .block-host {
          text-align: left; } }
      .schedule .block-selections .block-info > .block-host .content ul {
        text-align: left; }
      .schedule .block-selections .block-info > .block-host .content iframe {
        width: 46.875rem;
        height: 26.375rem; }
        @media only screen and (min-width: 751px) {
          .schedule .block-selections .block-info > .block-host .content iframe {
            width: 53.33333rem;
            height: 30rem; } }
        @media only screen and (min-width: 1271px) {
          .schedule .block-selections .block-info > .block-host .content iframe {
            width: 93rem;
            height: 52.5rem; } }
      .schedule .block-selections .block-info > .block-host > .title {
        font-family: "Hitchcock", san-serif;
        color: #FFF;
        background-color: #000;
        font-size: 3.75rem;
        line-height: 3.75rem;
        padding: 0.625rem 1.25rem;
        margin-bottom: 0.625rem; }
        @media only screen and (min-width: 751px) {
          .schedule .block-selections .block-info > .block-host > .title {
            font-size: 2.5rem;
            line-height: 2.83333rem;
            padding: 0.83333rem 0.83333rem;
            margin-bottom: 0.83333rem; } }
        @media only screen and (min-width: 1271px) {
          .schedule .block-selections .block-info > .block-host > .title {
            font-size: 3.6rem;
            line-height: 4rem;
            padding: 1rem 1rem;
            margin-bottom: 1rem; } }
      .schedule .block-selections .block-info > .block-host > .hosted-by {
        font-family: "Hitchcock", san-serif;
        font-size: 1.75rem;
        line-height: 2rem; }
        @media only screen and (min-width: 751px) {
          .schedule .block-selections .block-info > .block-host > .hosted-by {
            font-size: 1.75rem;
            line-height: 2rem; } }
        @media only screen and (min-width: 1271px) {
          .schedule .block-selections .block-info > .block-host > .hosted-by {
            font-size: 2.1rem;
            line-height: 2.4rem; } }
      .schedule .block-selections .block-info > .block-host > .host-name {
        font-family: "Hitchcock", san-serif;
        font-size: 2.25rem;
        line-height: 2.5rem; }
        @media only screen and (min-width: 751px) {
          .schedule .block-selections .block-info > .block-host > .host-name {
            font-size: 2.33333rem;
            line-height: 2.66667rem; } }
        @media only screen and (min-width: 1271px) {
          .schedule .block-selections .block-info > .block-host > .host-name {
            font-size: 3.6rem;
            line-height: 4rem; } }

.selections {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media only screen and (min-width: 751px) {
    .selections {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      font-size: 2.33333rem;
      line-height: 2.66667rem; } }
  .selections .thumb-selection {
    margin: 0.625rem; }
    @media only screen and (min-width: 751px) {
      .selections .thumb-selection {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin: 1.25rem 1.25rem 1.25rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selections .thumb-selection {
        margin: 2.2rem 2.2rem 2.2rem 0; } }

.schedule-thumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .schedule-thumbs {
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin: 1.25rem; } }
  @media only screen and (min-width: 1271px) {
    .schedule-thumbs {
      margin: 2rem; } }
  .schedule-thumbs.menu {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    margin: 0; }
    .schedule-thumbs.menu .thumb-schedule .poster-thumb {
      width: 15.625rem;
      margin-right: 1.25rem; }
      @media only screen and (min-width: 751px) {
        .schedule-thumbs.menu .thumb-schedule .poster-thumb {
          width: 8.33333rem;
          margin-right: 0.83333rem; } }
      @media only screen and (min-width: 1271px) {
        .schedule-thumbs.menu .thumb-schedule .poster-thumb {
          width: 12rem;
          margin-right: 1rem; } }

.thumb-schedule {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0.9375rem 0.3125rem; }
  @media only screen and (min-width: 751px) {
    .thumb-schedule {
      margin: 0.41667rem 0.41667rem 0.83333rem 0.41667rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-schedule {
      margin: 0.5rem 0.5rem 1rem 0.5rem; } }
  .thumb-schedule .poster-thumb {
    width: 15.625rem;
    margin-right: 1.25rem; }
    @media only screen and (min-width: 751px) {
      .thumb-schedule .poster-thumb {
        width: 8.33333rem;
        margin-right: 0.83333rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-schedule .poster-thumb {
        width: 15rem;
        margin-right: 1rem; } }
  .thumb-schedule .info {
    width: 28.75rem; }
    @media only screen and (min-width: 751px) {
      .thumb-schedule .info {
        width: 14.58333rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-schedule .info {
        width: 20rem; } }
    .thumb-schedule .info .title {
      text-align: left;
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 700;
      color: #000;
      text-transform: uppercase;
      font-size: 1.875rem;
      line-height: 2.25rem; }
      @media only screen and (min-width: 751px) {
        .thumb-schedule .info .title {
          font-size: 1.5rem;
          line-height: 1.75rem; } }
      @media only screen and (min-width: 1271px) {
        .thumb-schedule .info .title {
          font-size: 1.8rem;
          line-height: 2.1rem; } }
    .thumb-schedule .info .selections {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start; }

.date-tickets {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  @media only screen and (min-width: 751px) {
    .date-tickets {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .date-tickets .schedule-date {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.875rem; }
    @media only screen and (min-width: 751px) {
      .date-tickets .schedule-date {
        margin: 0.83333rem 0.83333rem 0.83333rem 0; } }
    @media only screen and (min-width: 1271px) {
      .date-tickets .schedule-date {
        margin: 1rem 1rem 1rem 0; } }
    .date-tickets .schedule-date .day-holder {
      margin-right: 0.625rem; }
      @media only screen and (min-width: 751px) {
        .date-tickets .schedule-date .day-holder {
          margin-right: 0.83333rem; } }
      @media only screen and (min-width: 1271px) {
        .date-tickets .schedule-date .day-holder {
          margin-right: 1rem; } }
      .date-tickets .schedule-date .day-holder .day {
        font-family: "Hitchcock", san-serif;
        color: #FFF;
        background-color: #000;
        font-size: 3.75rem;
        padding: 2.8125rem 1.5625rem; }
        @media only screen and (min-width: 751px) {
          .date-tickets .schedule-date .day-holder .day {
            font-size: 1.83333rem;
            padding: 1.66667rem 0.83333rem; } }
        @media only screen and (min-width: 1271px) {
          .date-tickets .schedule-date .day-holder .day {
            font-size: 2.8rem;
            padding: 2rem 1rem; } }
    .date-tickets .schedule-date .time-date .time {
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 700;
      color: #3375e2;
      font-size: 3rem;
      line-height: 4.0625rem; }
      @media only screen and (min-width: 751px) {
        .date-tickets .schedule-date .time-date .time {
          font-size: 2rem;
          line-height: 2.33333rem; } }
      @media only screen and (min-width: 1271px) {
        .date-tickets .schedule-date .time-date .time {
          font-size: 2.8rem;
          line-height: 3.3rem; } }
    .date-tickets .schedule-date .time-date .date {
      color: #000;
      font-size: 2.25rem; }
      @media only screen and (min-width: 751px) {
        .date-tickets .schedule-date .time-date .date {
          font-size: 1.5rem; } }
      @media only screen and (min-width: 1271px) {
        .date-tickets .schedule-date .time-date .date {
          font-size: 1.8rem; } }

.tickets-btn {
  text-align: center;
  display: inline-block;
  font-family: "Hitchcock", san-serif;
  color: #FFF;
  background-color: #fe0000;
  font-size: 2.25rem;
  line-height: 2.25rem;
  padding: 0.9375rem;
  margin: 1.25rem 0.625rem; }
  @media only screen and (min-width: 751px) {
    .tickets-btn {
      font-size: 1.83333rem;
      line-height: 1.83333rem;
      padding: 0.83333rem;
      margin: 0 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .tickets-btn {
      font-size: 2.8rem;
      line-height: 2.8rem;
      padding: 0.5rem 1.5rem;
      margin: 0 2rem; } }

.tickets-btn:hover {
  background-color: #3375e2; }

.category-news .thumb-holder {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .category-news .thumb-holder {
      margin: 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .category-news .thumb-holder {
      margin: 5rem; } }

.thumb-news {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 3.125rem; }
  @media only screen and (min-width: 751px) {
    .thumb-news {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      margin-bottom: 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-news {
      margin-bottom: 5rem; } }
  .thumb-news .thumb-img {
    width: 43.125rem;
    margin: 0 0 1.875rem 0; }
    @media only screen and (min-width: 751px) {
      .thumb-news .thumb-img {
        width: 26.66667rem;
        margin: 0 4.16667rem 0 0; } }
    @media only screen and (min-width: 1271px) {
      .thumb-news .thumb-img {
        width: 40rem;
        margin: 0 5rem 0 0; } }
  .thumb-news .info {
    width: 43.125rem; }
    @media only screen and (min-width: 751px) {
      .thumb-news .info {
        width: 41.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-news .info {
        width: 66rem; } }
    .thumb-news .info > .title {
      display: inline;
      font-family: "Hitchcock", san-serif;
      color: #3375e2;
      font-size: 3.125rem;
      line-height: 3.125rem;
      margin-bottom: 0.9375rem; }
      @media only screen and (min-width: 751px) {
        .thumb-news .info > .title {
          font-size: 2.33333rem;
          line-height: 2.66667rem;
          margin-bottom: 1.25rem; } }
      @media only screen and (min-width: 1271px) {
        .thumb-news .info > .title {
          font-size: 3.6rem;
          line-height: 4rem;
          margin-bottom: 1rem; } }
    .thumb-news .info > .date {
      font-weight: 700;
      margin-bottom: 0.9375rem; }
      @media only screen and (min-width: 751px) {
        .thumb-news .info > .date {
          margin-bottom: 1.25rem; } }
      @media only screen and (min-width: 1271px) {
        .thumb-news .info > .date {
          margin-bottom: 1rem; } }

.nominees {
  padding: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .nominees {
      padding: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .nominees {
      padding: 2rem; } }
  .nominees .award-category {
    margin-bottom: 1.25rem; }
    @media only screen and (min-width: 751px) {
      .nominees .award-category {
        margin-bottom: 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .nominees .award-category {
        margin-bottom: 2rem; } }
    .nominees .award-category .kapowee {
      margin: 0 auto; }
    .nominees .award-category .selections {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }

.awards {
  padding: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .awards {
      padding: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .awards {
      padding: 2rem; } }
  .awards .award-category {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.25rem; }
    @media only screen and (min-width: 751px) {
      .awards .award-category {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-bottom: 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .awards .award-category {
        margin-bottom: 2rem; } }
    .awards .award-category .selections {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }

.award-selection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 0.75rem; }
  @media only screen and (min-width: 751px) {
    .award-selection {
      margin: 0 0.83333rem; } }
  @media only screen and (min-width: 1271px) {
    .award-selection {
      margin: 0 2.5rem; } }
  .award-selection .poster-thumb {
    border: 1px solid #000;
    width: 12.5rem;
    margin: 1.25rem 1.25rem 1.25rem 0; }
    @media only screen and (min-width: 751px) {
      .award-selection .poster-thumb {
        width: 10rem;
        margin: 1.66667rem 0 1.66667rem 0; } }
    @media only screen and (min-width: 1271px) {
      .award-selection .poster-thumb {
        width: 15rem;
        margin: 1rem 0 1rem 0; } }
  .award-selection .info {
    width: 23.75rem;
    margin: 0 0 0 1.25rem; }
    @media only screen and (min-width: 751px) {
      .award-selection .info {
        width: 33.33333rem;
        margin: 0 0 0 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .award-selection .info {
        width: 50rem;
        margin: 0 0 0 2rem; } }
    .award-selection .info.wide {
      width: 37.5rem;
      text-align: center; }
      @media only screen and (min-width: 751px) {
        .award-selection .info.wide {
          text-align: left; } }
    .award-selection .info .title {
      display: block;
      font-family: "Hitchcock", san-serif;
      font-weight: 700;
      color: #3375e2;
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 2.625rem;
      margin: 2.5rem 0 0.625rem; }
      @media only screen and (min-width: 751px) {
        .award-selection .info .title {
          font-size: 2.5rem;
          line-height: 2.5rem;
          margin: 2.5rem 0 1.25rem 0; } }
      @media only screen and (min-width: 1271px) {
        .award-selection .info .title {
          font-size: 3.6rem;
          line-height: 3.6rem;
          margin: 2rem 0; } }
    .award-selection .info .castcrew {
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 700;
      font-size: 1.3125rem;
      line-height: 1.5rem;
      margin: 0.3125rem 0; }
      @media only screen and (min-width: 751px) {
        .award-selection .info .castcrew {
          font-size: 1.41667rem;
          line-height: 1.75rem;
          margin: 0.41667rem 0; } }
      @media only screen and (min-width: 1271px) {
        .award-selection .info .castcrew {
          font-size: 1.8rem;
          line-height: 1.8rem;
          margin: 0.5rem 0; } }
    .award-selection .info .synopsis {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 0.625rem 0; }
      @media only screen and (min-width: 751px) {
        .award-selection .info .synopsis {
          font-size: 1.25rem;
          line-height: 1.58333rem;
          margin: 0.83333rem 0; } }
      @media only screen and (min-width: 1271px) {
        .award-selection .info .synopsis {
          font-size: 1.5rem;
          line-height: 2.1rem;
          margin: 1rem 0; } }
      .award-selection .info .synopsis p {
        margin: 0; }

.selection header {
  border: 1px solid #000;
  margin: 1.25rem; }
  @media only screen and (min-width: 751px) {
    .selection header {
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .selection header {
      margin: 2rem; } }

.selection .info {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 1.875rem; }
  @media only screen and (min-width: 751px) {
    .selection .info {
      text-align: left;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin: 1.66667rem; } }
  @media only screen and (min-width: 1271px) {
    .selection .info {
      margin: 2rem; } }
  .selection .info .poster-social {
    margin: 1.25rem 0 3.125rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .poster-social {
        margin: 0 1.66667rem 0 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .poster-social {
        margin-right: 0 2rem 0 0; } }
    .selection .info .poster-social .poster-thumb {
      cursor: pointer;
      display: inline-block;
      border: 1px solid #000;
      width: 34.375rem; }
      @media only screen and (min-width: 751px) {
        .selection .info .poster-social .poster-thumb {
          width: 18.75rem; } }
      @media only screen and (min-width: 1271px) {
        .selection .info .poster-social .poster-thumb {
          width: 22.5rem; } }
    .selection .info .poster-social .social {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
  .selection .info .date-tickets .tickets-btn {
    max-width: 12.5rem; }
    @media only screen and (min-width: 751px) {
      .selection .info .date-tickets .tickets-btn {
        max-width: 16.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .date-tickets .tickets-btn {
        max-width: 20rem; } }
  .selection .info .info-txt > .title {
    display: inline-block;
    font-family: "Hitchcock", san-serif;
    color: #FFF;
    background-color: #000;
    font-size: 5rem;
    line-height: 5.625rem;
    padding: 0.9375rem 0.9375rem;
    margin: 0 0 3.125rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .info-txt > .title {
        font-size: 4.16667rem;
        line-height: 5.41667rem;
        padding: 1.25rem 1.25rem;
        margin: 0 0 2.08333rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .info-txt > .title {
        font-size: 5rem;
        line-height: 7.5rem;
        padding: 1.5rem 1.5rem;
        margin: 0 0 2.5rem 0; } }
  .selection .info .info-txt > .tagline {
    font-family: "Lato", sans-serif;
    color: #3375e2;
    font-size: 2.5rem;
    line-height: 3.4375rem;
    margin: 0 0 3.125rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .info-txt > .tagline {
        font-size: 2.33333rem;
        line-height: 2.75rem;
        margin: 0 0 2.08333rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .info-txt > .tagline {
        font-size: 2.8rem;
        line-height: 3.6rem;
        margin: 0 0 2.5rem 0; } }
  .selection .info h4 {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 3.4375rem;
    margin: 0; }
    @media only screen and (min-width: 751px) {
      .selection .info h4 {
        font-size: 1.75rem;
        line-height: 2.75rem; } }
    @media only screen and (min-width: 1271px) {
      .selection .info h4 {
        font-size: 2.1rem;
        line-height: 3.6rem; } }
  .selection .info .aspects h4 {
    display: inline-block; }
  .selection .info .synopsis {
    margin: 2.1875rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .synopsis {
        margin: 1.25rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .synopsis {
        margin: 1.5rem 0; } }
    .selection .info .synopsis p {
      margin: 0 0 0.9375rem 0; }
      @media only screen and (min-width: 751px) {
        .selection .info .synopsis p {
          margin: 0 0 1.25rem 0; } }
      @media only screen and (min-width: 1271px) {
        .selection .info .synopsis p {
          margin: 0 0 1.5rem 0; } }
  .selection .info .cast-crew {
    margin: 0.9375rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .cast-crew {
        margin: 1.25rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .cast-crew {
        margin: 1.5rem 0; } }
    .selection .info .cast-crew .member > .title {
      font-weight: 700;
      font-style: italic; }
  .selection .info .links {
    margin: 0.9375rem 0; }
    @media only screen and (min-width: 751px) {
      .selection .info .links {
        margin: 1.25rem 0; } }
    @media only screen and (min-width: 1271px) {
      .selection .info .links {
        margin: 1.5rem 0; } }
    .selection .info .links a {
      margin-right: 1.5rem; }

.thumb-selection {
  width: 13.125rem;
  margin: 0.9375rem 0.75rem; }
  @media only screen and (min-width: 751px) {
    .thumb-selection {
      width: 10rem;
      margin: 1.25rem 0.41667rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-selection {
      width: 15rem;
      margin: 1.5rem 0.5rem; } }
  .thumb-selection > .poster-thumb {
    width: inherit;
    border: 1px solid #000; }
    @media only screen and (min-width: 1271px) {
      .thumb-selection > .poster-thumb {
        margin-bottom: 1rem; } }
  .thumb-selection > .title {
    display: block;
    text-align: center;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    font-size: 2.0625rem;
    line-height: 2.25rem;
    margin: 1.25rem 0; }
    @media only screen and (min-width: 751px) {
      .thumb-selection > .title {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0.83333rem 0; } }
    @media only screen and (min-width: 1271px) {
      .thumb-selection > .title {
        font-size: 2.1rem;
        line-height: 2.4rem;
        margin: 1rem 0; } }

.thumb-selection-sm {
  width: 6.875rem; }
  @media only screen and (min-width: 751px) {
    .thumb-selection-sm {
      width: 2.91667rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-selection-sm {
      width: 5rem; } }
  .thumb-selection-sm > .poster-thumb-sm {
    width: inherit; }
  .thumb-selection-sm > .title {
    text-align: left;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 2.25rem; }
    @media only screen and (min-width: 751px) {
      .thumb-selection-sm > .title {
        font-size: 1.5rem;
        line-height: 2.5rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-selection-sm > .title {
        font-size: 2.4rem;
        line-height: 2.8rem; } }

.winner {
  text-align: center;
  font-family: "Open Sans Condensed", sans-serif;
  background-color: #d39500;
  color: #000;
  text-transform: uppercase; }
  .winner > .title {
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem; }
    @media only screen and (min-width: 751px) {
      .winner > .title {
        font-size: 1.5rem;
        line-height: 2.5rem; } }
    @media only screen and (min-width: 1271px) {
      .winner > .title {
        font-size: 2.4rem;
        line-height: 3rem; } }
  .winner > .desc {
    text-align: center;
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem; }
    @media only screen and (min-width: 751px) {
      .winner > .desc {
        font-size: 1.33333rem;
        line-height: 1.5rem; } }
    @media only screen and (min-width: 1271px) {
      .winner > .desc {
        font-size: 1.8rem;
        line-height: 2.1rem; } }

.horizontal-thumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 1.5625rem; }
  @media only screen and (min-width: 751px) {
    .horizontal-thumb {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
      margin: 2.08333rem; } }
  @media only screen and (min-width: 1271px) {
    .horizontal-thumb {
      margin: 2.5rem; } }
  .horizontal-thumb .poster-thumb {
    border: 1px solid #000;
    width: 25rem;
    margin: 0 0 1.5625rem 0; }
    @media only screen and (min-width: 751px) {
      .horizontal-thumb .poster-thumb {
        width: 12.5rem;
        margin: 0 2.08333rem 0 0; } }
    @media only screen and (min-width: 1271px) {
      .horizontal-thumb .poster-thumb {
        width: 15rem;
        margin: 0 2.5rem 0 0; } }
  .horizontal-thumb .info {
    width: 43.125rem; }
    @media only screen and (min-width: 751px) {
      .horizontal-thumb .info {
        width: 41.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .horizontal-thumb .info {
        width: 90rem; } }
    .horizontal-thumb .info > .title {
      display: inline;
      font-family: "Hitchcock", san-serif;
      color: #3375e2;
      font-size: 4.5rem;
      line-height: 4.8125rem;
      margin-bottom: 1.5625rem; }
      @media only screen and (min-width: 751px) {
        .horizontal-thumb .info > .title {
          font-size: 2.33333rem;
          line-height: 2.66667rem;
          margin-bottom: 1.25rem; } }
      @media only screen and (min-width: 1271px) {
        .horizontal-thumb .info > .title {
          font-size: 3.6rem;
          line-height: 4rem;
          margin-bottom: 1rem; } }
    .horizontal-thumb .info > .sub {
      color: #000;
      font-weight: 700;
      margin-bottom: 0.9375rem; }
      @media only screen and (min-width: 751px) {
        .horizontal-thumb .info > .sub {
          margin-bottom: 1.25rem; } }
      @media only screen and (min-width: 1271px) {
        .horizontal-thumb .info > .sub {
          margin-bottom: 1rem; } }

#festival-lineup p {
  margin: 0; }

#festival-lineup tr {
  vertical-align: top; }
  #festival-lineup tr td {
    width: 5%;
    padding: 1%; }

.featurette-vid {
  width: 46.875rem;
  height: 26.375rem; }
  @media only screen and (min-width: 751px) {
    .featurette-vid {
      width: 80.83333rem;
      height: 45.5rem; } }
  @media only screen and (min-width: 1271px) {
    .featurette-vid {
      width: 125rem;
      height: 70rem; } }

.thumb-featurette {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 37.5rem; }
  @media only screen and (min-width: 751px) {
    .thumb-featurette {
      width: 25rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-featurette {
      width: 28rem; } }
  .thumb-featurette img {
    border: 1px solid #000; }
  .thumb-featurette h3 {
    color: #000;
    text-align: center;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 2.0625rem; }
    @media only screen and (min-width: 751px) {
      .thumb-featurette h3 {
        font-size: 1.5rem;
        line-height: 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-featurette h3 {
        font-size: 2.1rem;
        line-height: 2.4rem; } }

.thumb-hosts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 3.125rem 3.125rem 3.125rem; }
  @media only screen and (min-width: 751px) {
    .thumb-hosts {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin: 0 0 0 4.16667rem; } }
  @media only screen and (min-width: 1271px) {
    .thumb-hosts {
      margin: 0 5rem 5rem 5rem; } }
  .thumb-hosts > .thumb-img {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: 40.625rem;
    margin: 0 0 3.125rem 0; }
    @media only screen and (min-width: 751px) {
      .thumb-hosts > .thumb-img {
        width: 25rem;
        margin: 0 0 0 4.16667rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-hosts > .thumb-img {
        width: 30rem;
        margin: 0 5rem 0 0; } }
  .thumb-hosts > .info {
    width: 40.625rem; }
    @media only screen and (min-width: 751px) {
      .thumb-hosts > .info {
        width: 12.5rem; } }
    @media only screen and (min-width: 1271px) {
      .thumb-hosts > .info {
        width: 75rem; } }
    .thumb-hosts > .info > .title {
      text-align: left;
      font-family: "Open Sans Condensed", sans-serif;
      font-weight: 700;
      color: #000;
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 2.75rem; }
      @media only screen and (min-width: 751px) {
        .thumb-hosts > .info > .title {
          font-size: 1.5rem;
          line-height: 2.5rem; } }
      @media only screen and (min-width: 1271px) {
        .thumb-hosts > .info > .title {
          font-size: 3.6rem;
          line-height: 4rem; } }

#footer {
  font-family: 'Khula', sans-serif;
  font-weight: 400;
  width: 100vw;
  color: #fff;
  text-align: center;
  background-color: #000;
  padding: 1.25rem 0;
  font-size: 1.125rem; }
  @media only screen and (min-width: 751px) {
    #footer {
      padding: 1.66667rem 0;
      font-size: 1rem; } }
  @media only screen and (min-width: 1271px) {
    #footer {
      padding: 2rem 0;
      font-size: 1.2rem; } }

#footer a:link {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:link {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:link {
      color: #b6b6b6; } }

#footer a:visited {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:visited {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:visited {
      color: #b6b6b6; } }

#footer a:hover {
  color: #FFF;
  text-decoration: underline; }
  @media only screen and (min-width: 751px) {
    #footer a:hover {
      color: #FFF; } }
  @media only screen and (min-width: 1271px) {
    #footer a:hover {
      color: #FFF; } }

#footer a:active {
  color: #b6b6b6;
  text-decoration: none; }
  @media only screen and (min-width: 751px) {
    #footer a:active {
      color: #b6b6b6; } }
  @media only screen and (min-width: 1271px) {
    #footer a:active {
      color: #b6b6b6; } }

select,
.submit-btn {
  position: relative;
  font-family: "Hitchcock", san-serif;
  color: #fff;
  background-color: #3375e2;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1.875rem;
  padding: 0.9375rem 0.9375rem;
  margin: 0.625rem 0.625rem;
  width: 80%; }
  @media only screen and (min-width: 751px) {
    select,
    .submit-btn {
      width: 25%;
      font-size: 2.08333rem;
      padding: 0.83333rem 0.83333rem;
      margin: 1.66667rem 0.83333rem; } }
  @media only screen and (min-width: 1271px) {
    select,
    .submit-btn {
      font-size: 2.5rem;
      padding: 1rem 1rem;
      margin: 2rem 1rem; } }

select:after {
  content: "<>";
  font-family: Font Awesome\ 5 Free;
  color: #000;
  padding: 12px 8px;
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  z-index: 1;
  text-align: center;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.submit-btn {
  cursor: pointer;
  background-color: #000;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  .submit-btn:hover {
    background-color: #3375e2; }

#filter-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1.875rem 0 0.625rem; }
  @media only screen and (min-width: 751px) {
    #filter-nav {
      margin: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }

.kapowee {
  font-family: "Hitchcock", san-serif;
  text-align: center;
  background-image: url("/logos/kapowee.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 31.25rem;
  height: 16.66667rem;
  padding: 1.5625rem 1.25rem 0 1.25rem;
  margin: 0 0 1.66667rem 0; }
  @media only screen and (min-width: 751px) {
    .kapowee {
      width: 21.66667rem;
      height: 13.33333rem;
      padding: 1.25rem 1.66667rem 0 1.66667rem;
      margin: 1.66667rem 1.66667rem 1.66667rem 0; } }
  @media only screen and (min-width: 1271px) {
    .kapowee {
      width: 30rem;
      height: 16rem;
      padding: 2rem 2rem 0 2rem;
      margin: 2rem 2rem 2rem 0; } }
  .kapowee .title {
    margin: 0;
    font-size: 2.75rem; }
    @media only screen and (min-width: 751px) {
      .kapowee .title {
        font-size: 2rem; } }
    @media only screen and (min-width: 1271px) {
      .kapowee .title {
        font-size: 2.2rem; } }
  .kapowee .year {
    margin: 10.625rem 0 0 0;
    font-size: 2.5rem;
    line-height: 1.125rem; }
    @media only screen and (min-width: 751px) {
      .kapowee .year {
        margin: 7.08333rem 0 0 0;
        font-size: 1.5rem;
        line-height: 1.5rem; } }
    @media only screen and (min-width: 1271px) {
      .kapowee .year {
        margin: 10.5rem 0 0 0;
        font-size: 1.6rem;
        line-height: 1.6rem; } }
  .kapowee.lg {
    width: 37.5rem;
    height: 20rem;
    padding: 2.1875rem 1.25rem 0 1.25rem; }
    @media only screen and (min-width: 751px) {
      .kapowee.lg {
        width: 25rem;
        height: 13.33333rem;
        padding: 1.66667rem 1.66667rem 0 1.66667rem; } }
    @media only screen and (min-width: 1271px) {
      .kapowee.lg {
        width: 50rem;
        height: 24rem;
        padding: 3.5rem 0 0 0; } }
    .kapowee.lg .title {
      margin: 0;
      font-size: 2.75rem; }
      @media only screen and (min-width: 751px) {
        .kapowee.lg .title {
          font-size: 2rem; } }
      @media only screen and (min-width: 1271px) {
        .kapowee.lg .title {
          font-size: 3.3rem; } }
    .kapowee.lg .year {
      margin: 12.5rem 0 1.5625rem;
      font-size: 2.5rem;
      line-height: 2.5rem; }
      @media only screen and (min-width: 751px) {
        .kapowee.lg .year {
          margin: 8.33333rem 0 2.08333rem;
          font-size: 1.8rem;
          line-height: 1.8rem; } }
      @media only screen and (min-width: 1271px) {
        .kapowee.lg .year {
          margin: 15.5rem 0 1.5rem;
          font-size: 2.4rem;
          line-height: 2.4rem; } }

.award a,
.award a:link,
.award a:active,
.award a:visited {
  color: #000; }
  .award a .kapowee,
  .award a:link .kapowee,
  .award a:active .kapowee,
  .award a:visited .kapowee {
    outline: 0px solid #3375e2;
    outline-offset: 0px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    display: inline-block; }

.award a:hover {
  color: #000; }
  .award a:hover .kapowee {
    outline: 5px solid #000;
    outline-offset: -5px; }
